export type FormMode = 'create' | 'edit';

export enum TicketStatuses {
  ticket_sent = 'ticket_sent',
  ticket_redeemed = 'ticket_redeemed',
  ticket_received = 'ticket_received',
}

export enum TicketStatusTypes {
  redeemed = 'redeemed',
  sent = 'sent',
  pending = 'pending',
  all = 'all',
  notsent = 'notsent',
}

export enum TicketAccessTypes {
  shared = 'shared',
  pending = 'pending',
}

export type TicketsCardT = {label?: string; value?: string; footNoteValue?: string; tooltip?: string};

export enum GroupDetailTableFields {
  no = 'no',
  email = 'email',
  name = 'name',
  admission = 'admission',
  skating = 'skating',
  parking = 'parking',
  actions = 'actions',
}

export const GroupDetailTableColumnsTitles = {
  no: 'No.',
  email: 'Email',
  name: 'Name',
  admission: 'Admission',
  skating: 'Skating',
  parking: 'Parking',
  actions: 'Actions',
};

export type GroupDetailTableDataT = {
  key?: string | number;
  id?: string;
  no?: {no?: string; id?: string; addRow?: boolean};
  email?: {edit?: boolean; email?: string; id?: string; addRow?: boolean};
  name?: {edit?: boolean; name?: string; email?: string | null; id?: string; addRow?: boolean};
  admission?: {count?: number; edit?: boolean; id?: string; addRow?: boolean};
  skating?: {count?: number; edit?: boolean; id?: string; addRow?: boolean};
  parking?: {count?: number; edit?: boolean; id?: string; addRow?: boolean};
  actions?: {edit?: boolean; ticketStatus?: string; id?: string; addRow?: boolean; email?: string};
  outboundStatus?: {outboundStatus?: string; id?: string; addRow?: boolean};
};

export type TicketGroupCreateFormValues = {
  brand?: string;
  clientName?: string;
  clientEmail?: string;
  clientEmailList?: string;
  contactPerson?: string;
  groupType?: string;
  department: string;
  ticketType?: string[];
  managedByUsers?: string[];
  description?: string;
  ticketureOrderId?: string;
  //metadata fields
  overrideDateField?: string;
};

export enum TicketGroupCreateFields {
  brand = 'brand',
  clientName = 'clientName',
  clientEmail = 'clientEmailList',
  groupType = 'groupType',
  contactPerson = 'contactPerson',
  department = 'department',
  ticketType = 'ticketType',
  description = 'description',
  ticketureOrderId = 'ticketureOrderId',
  managedByUsers = 'managedByUsers',
  overrideDateField = 'overrideDateField',
}

export enum TicketActionTypes {
  sent = 'sent',
  applied = 'applied',
}

export enum TicketSendStatuses {
  sent = 'sent',
  queued = 'queued',
  notsent = 'notsent',
}

export enum TicketType {
  adult_admission = 'adult_admission',
  child_admission = 'child_admission',
  adult_vip_admission = 'adult_vip_admission',
  child_vip_admission = 'child_vip_admission',
  adult_skating = 'adult_skating',
  minor_skating = 'minor_skating',
  parking = 'parking',
}

export type ticketColumnsT = {key: string; title: string}[];
export type AdmissionRow = {count?: number; edit?: boolean; id?: string; addRow?: boolean};

export type TicketTypeRow = {
  ticketDisplayName: string;
  ticketType: string;
  ticketTypeName: string;
  ticketTypeSent: number;
  ticketTypeAssigned: number;
  ticketTypeUploaded: number;
  eventTemplateName: string;
};

export type EmailsMapT = {id?: string; email?: string}[];
